import axios from "axios";
import { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Heading,
  Button,
} from "@chakra-ui/react";
import CountUp from "react-countup";
export default function GroupsTable({ campers }) {
  // const camperGroups = {}
  const [groups, setGroups] = useState(null);

  const createGroups = () => {
    var temp_groups = {
      "Group 1": 0,
      "Group 2": 0,
      "Group 3": 0,
      "Group 4": 0,
    };
    campers?.map((camper) => {
      if (
        camper.grade === "Grade 1" ||
        camper.grade === "Grade 2" ||
        camper.grade === "Grade 3" ||
        camper.grade === "Grade 4"
      ) {
        temp_groups["Group 1"] += 1;
      } else if (camper.grade === "Grade 5" || camper.grade === "Grade 6") {
        temp_groups["Group 2"] += 1;
      } else if (
        camper.grade === "Grade 7" ||
        camper.grade === "Grade 8" ||
        camper.grade === "Grade 9"
      ) {
        temp_groups["Group 3"] += 1;
      } else if (
        camper.grade === "Grade 10" ||
        camper.grade === "Grade 11" ||
        camper.grade === "Grade 12"
      ) {
        temp_groups["Group 4"] += 1;
      }
    });

    setGroups(temp_groups);
  };

  useEffect(() => {
    createGroups();
  }, [campers]);

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Group</Th>
            <Th isNumeric>Count</Th>
          </Tr>
        </Thead>
        <Tbody>
          {groups
            ? Object.entries(groups).map((key, val) => {
                // console.log(key);
                return (
                  <Tr>
                    <Td>{key[0]}</Td>
                    <Td isNumeric>
                      <CountUp end={key[1]} />
                    </Td>
                  </Tr>
                );
              })
            : ""}
        </Tbody>
        {/* <Tfoot>
          <Tr>
            <Th>To convert</Th>
            <Th>into</Th>
            <Th isNumeric>multiply by</Th>
          </Tr>
        </Tfoot> */}
      </Table>
    </TableContainer>
  );
}
