import {
  Heading,
  Text,
  HStack,
  Box,
  Container,
  Link,
  VStack,
} from "@chakra-ui/react";
// import { ExternalLinkIcon } from "@chakra-ui/icons";
export default function FlightInformationCard({
  camperFlightInfo,
  camperFirstName,
  camperLastName,
  emailContact,
}) {
  // console.log(camperFlightInfo);
  return (
    // https://www.flightaware.com/live/flight/SW1585
    <Box
      bg={
        camperFlightInfo.arrivialInformation.arrivalDate !== ""
          ? "lightgreen"
          : "#FF7D8C"
      }
      rounded={"lg"}
      minH="25vh"
      //   minW="20vw"
      textAlign={"left"}
      pt={"2"}
      pl={"4"}
      _hover={{ transform: "transition(width 1s ease-in-out)" }}
    >
      <Heading as={"h3"} size={"md"} color="black" mb={"0.5rem"}>
        {`${camperFirstName} ${camperLastName}`}
      </Heading>
      <HStack
        w={"100%"}
        pr={"4"}
        // _hover={{
        //   fontWeight: "bold",
        //   cursor: "pointer",
        //   textColor: "darkgrey",
        // }}
      >
        <Text>FLT Info Status</Text>
        <Text textAlign={"right"} flexGrow={1}>
          {camperFlightInfo.arrivialInformation.arrivalDate !== ""
            ? "✅"
            : "❌"}
        </Text>
      </HStack>
      <Box>
        <Heading size={"sm"} color="black" mt={"0.25rem"}>
          FLT{" "}
          <span textColor="blue">
            {camperFlightInfo?.arrivialInformation.arrivalFlightNumber}
          </span>{" "}
          Arrives {camperFlightInfo?.arrivialInformation.arrivalDate}
        </Heading>
        {/* <HStack
          w={"100%"}
          pr={"4"}
          _hover={{
            fontWeight: "bold",
            cursor: "pointer",
            textColor: "darkgrey",
          }}
        >
          <Text>🛬 📍</Text>
          <Text textAlign={"right"} flexGrow={1}>
            HOU
          </Text>
        </HStack> */}
        <HStack
          w={"100%"}
          pr={"4"}
          _hover={{
            fontWeight: "bold",
            cursor: "pointer",
            textColor: "darkgrey",
          }}
        >
          <Text>🛬 🕐</Text>
          <Text textAlign={"right"} flexGrow={1}>
            {/* 6:24PM */}
            {camperFlightInfo?.arrivialInformation.arrivalTime}
          </Text>
        </HStack>
      </Box>
      <Box mt={".5rem"}>
        <Heading size={"sm"} color="black" mt={"0.25rem"}>
          FLT{" "}
          <span textColor="blue">
            {camperFlightInfo?.departureInformation.departureFlightNumber}
          </span>{" "}
          Arrives {camperFlightInfo?.departureInformation.departureDate}
        </Heading>
        {/* <HStack
          w={"100%"}
          pr={"4"}
          _hover={{
            fontWeight: "bold",
            cursor: "pointer",
            textColor: "darkgrey",
          }}
        >
          <Text>🛫 📍</Text>
          <Text textAlign={"right"} flexGrow={1}>
            HOU
          </Text>
        </HStack> */}
        <HStack
          w={"100%"}
          pr={"4"}
          _hover={{
            fontWeight: "bold",
            cursor: "pointer",
            textColor: "darkgrey",
          }}
        >
          <Text>🛫 🕐 </Text>
          <Text textAlign={"right"} flexGrow={1}>
            {camperFlightInfo?.departureInformation.departureTime}
          </Text>
        </HStack>
      </Box>
      {camperFlightInfo.arrivialInformation.arrivalDate !== "" ? (
        <VStack alignItems={"start"} spacing={"0"}>
          <Link
            href={`https://www.flightaware.com/live/flight/${camperFlightInfo?.arrivialInformation.arrivalFlightNumber}`}
            isExternal
          >
            More Arrival Info
          </Link>
          <Link
            href={`https://www.flightaware.com/live/flight/${camperFlightInfo?.departureInformation.departureFlightNumber}`}
            isExternal
          >
            More Departure Info
          </Link>
        </VStack>
      ) : (
        <HStack
          w={"100%"}
          pr={"4"}
          fontWeight="bold"
          _hover={{
            cursor: "pointer",
            // textColor: "darkgrey",
          }}
        >
          <Text>Contact</Text>
          {/* <Text textAlign={"right"} flexGrow={1}> */}
          <Link
            textAlign={"right"}
            flexGrow={1}
            href={`mailto:${emailContact}`}
            isExternal
          >
            {emailContact}
          </Link>

          {/* </Text> */}
        </HStack>
      )}
    </Box>
  );
}
