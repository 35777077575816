import { useState } from "react";
import { Form, FormProvider, useForm } from "react-hook-form";
import {
  Container,
  Divider,
  Button,
  Heading,
  HStack,
  Center,
  Stack,
  Alert,
  AlertIcon,
  Link,
  Box,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import CamperInfoSection from "../components/CamperInfoSection";
import ParentInfoSection from "../components/ParentInfoSection";
import axios from "axios";
import ReigstrationAgreement from "../components/RegistrationAgreement";
import { toast } from "react-hot-toast";
import Footer from "../components/Footer";
import colors from "../utils/color_codes";
export default function RegistrationPage() {
  const methods = useForm({ shouldUnregister: true });
  const [date, setDate] = useState(new Date());
  const [loading, setIsLoading] = useState(false);
  const [numberOfSections, setNumberOfSections] = useState(1);
  const navigate = useNavigate();
  const showToast = () =>
    toast.success(
      "Your registration was successful! Please be on the lookout for a confirmation email soon!"
    );
  const [didError, setDidError] = useState(false);

  const onSubmit = (d) => {
    setIsLoading(true);
    var data = {
      guardianInformation: d.guardianInformation,
      campersInformation: d.campersInformation,
      agreement: d.agreement,
    };
    data.campersInformation.forEach((ele, index) => {
      ele.birthDate = `${ele.month}/${ele.day}/${ele.year}`;
    });
    // console.log(data);
    axios
      .post(`https://api.houstonsikhyouthcamp.org/submit`, { data })
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          showToast();
          navigate("/");
        }
      })
      .catch((e) => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        setDidError(true);
        setIsLoading(false);
      });
  };
  return (
    <>
      {didError && (
        <Alert status="error">
          <AlertIcon />
          <p>
            There was an error processing your request, please follow this{" "}
            <Link
              to="https://docs.google.com/forms/d/e/1FAIpQLSdoFa0NkSIySO6KEttZnlFltodF5ZgkwKMwOCk-BORzgb1aWA/viewform"
              color={"blue"}
            >
              link to retry our form
            </Link>
          </p>
        </Alert>
      )}
      <Heading
        as="h1"
        size="3xl"
        background={colors.announcementColor}
        padding={"1rem"}
        textAlign={"center"}
      >
        New Camper Registration - HSYC 2024
      </Heading>
      <Container maxW="100%" paddingTop={"1rem"}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {[...Array(numberOfSections)].map((num, idx) => {
              return <CamperInfoSection section_number={idx} />;
            })}
            <Center pt={"1rem"}>
              <Stack
                spacing="1em"
                margin={"auto"}
                direction={["column", "row"]}
              >
                <Button
                  onClick={() => {
                    setNumberOfSections(numberOfSections + 1);
                  }}
                  backgroundColor={"green"}
                  color="white"
                  w="15rem"
                  _hover={{
                    opacity: 0.7,
                  }}
                >
                  Add Another Camper
                </Button>
                {/* <Spacer /> */}
                <Button
                  onClick={() => {
                    if (numberOfSections > 1) {
                      setNumberOfSections(numberOfSections - 1);
                    }
                  }}
                  backgroundColor={"red"}
                  color="white"
                  w="15rem"
                  _hover={
                    numberOfSections === 1
                      ? { backgroundColor: "red", cursor: "none" }
                      : { opacity: 0.7 }
                  }
                >
                  Remove Camper
                </Button>
              </Stack>
            </Center>

            <Divider
              my={"1rem"}
              w={"50%"}
              marginRight="auto"
              marginLeft="auto"
            />
            <ParentInfoSection />
            <ReigstrationAgreement />
            <Container maxW="80%" pt={"2rem"}>
              <Button
                colorScheme="green"
                type="submit"
                my={"1rem"}
                w={"30%"}
                isLoading={loading}
                loadingText={"Submitting..."}
              >
                Submit
              </Button>
            </Container>
          </form>
        </FormProvider>
      </Container>
      <Footer />
    </>
  );
}
