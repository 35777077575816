import {
  Container,
  Heading,
  Center,
  VStack,
  Box,
  Divider,
  Link,
} from "@chakra-ui/react";
import * as dayjs from "dayjs";
import colors from "../utils/color_codes";

export default function ActionSection() {
  return (
    <>
      <Center
        h={"45vh"}
        bg={colors.mainBackgroundColor}
        textColor={colors.textColor}
        id="action-section"
        textAlign={"center"}
      >
        <VStack>
          <Heading as="h1" size={"3xl"} textColor={"#f8f8ff"}>
            HOUSTON SIKH YOUTH CAMP
          </Heading>
          {dayjs().isBefore(dayjs("2024-12-18 23:59")) &&
            dayjs().isAfter(dayjs("2024-10-12 23:59")) && (
              <Heading as="h3" size={"lg"} color="#FFE79A" opacity={0.8}>
                <Link href="/register">REGISTRATION IS NOW OPEN</Link>
              </Heading>
            )}
          {/* <Divider color={"black"} /> */}
          <Heading as="h3" size={"md"}>
            Dec 22nd, 2024 - Dec 28th, 2024
          </Heading>
          <Heading as="h3" size={"lg"}>
            Sangat · Seva · Simran
          </Heading>
        </VStack>
      </Center>
    </>
  );
}
