import { useState } from "react";
import { admin_navigation_items } from "../utils/NavigationItems";
import { Button, HStack, Link, Text } from "@chakra-ui/react";

export default function AdminNavigation() {
  return (
    <>
      <HStack
        spacing={"1rem"}
        bg="#2B2D42"
        justify={"left"}
        style={{
          paddingRight: "1rem",
          height: "10vh",
          overflowY: "auto",
          paddingLeft: "5rem",
        }}
      >
        {admin_navigation_items.map((item) => {
          return item.enabled ? (
            <Link href={`${item.href_field}`}>
              <Button
                _hover={{
                  //   background: "#191919",
                  textColor: "#191919",
                  cursor: "pointer",
                }}
                bg="#2B2D42"
                textColor={"white"}
                style={{ minWidth: "5rem" }}
              >
                <Text fontSize={"xl"} as="b">
                  {item.display_name}
                </Text>
              </Button>
            </Link>
          ) : (
            ""
          );
        })}
      </HStack>
    </>
  );
}
