import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import { useEffect } from "react";
import CountUp from "react-countup";

export default function ShirtSizeTable({ camper_shirt_sizes }) {
  useEffect(() => {
    console.log(camper_shirt_sizes);
  }, []);
  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Shirt Size</Th>
            <Th isNumeric>Count</Th>
          </Tr>
        </Thead>
        <Tbody>
          {Object.entries(camper_shirt_sizes).map((key, val) => {
            // console.log(key);
            return (
              <Tr>
                <Td>{key[0]}</Td>
                <Td isNumeric>
                  <CountUp end={key[1]} />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
        {/* <Tfoot>
          <Tr>
            <Th>To convert</Th>
            <Th>into</Th>
            <Th isNumeric>multiply by</Th>
          </Tr>
        </Tfoot> */}
      </Table>
    </TableContainer>
  );
}
