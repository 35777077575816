import {
  FaLocationDot,
  FaPeopleGroup,
  FaMoneyBillTransfer,
} from "react-icons/fa6";
export const campInformation = {
  wintercampFAQ: [
    { type: "date", day: "23", month: "Dec", fullInfo: "December 23rd, 2024" },
    { type: "date", day: "30", month: "Dec", fullInfo: "December 30th, 2024" },
    {
      type: "icon",
      icon: <FaLocationDot />,
      fullInfo: "Camp Cullen, Trinity, TX",
    },
    {
      type: "icon",
      icon: <FaPeopleGroup color="white" fontSize={"3rem"} />,
      fullInfo: "Children Grades 1 - 12",
    },
    {
      type: "icon",
      icon: <FaPeopleGroup />,
      fullInfo: "Children Grades 1 - 12",
    },
    {
      type: "icon",
      icon: <FaMoneyBillTransfer />,
      fullInfo: "$280 for first child $250 for every subsequent child after",
    },
  ],
  wintercamp: {
    id: "winter-camp",
    enabled: true,
    camp_title: "WINTER CAMP 2024",
    registration_information: "REGISTRATION IS NOW OPEN",
    registration_link: "/register",
    informational_blobs: [
      {
        title: "CAMP START & DROPOFF",
        data: "Sunday, December 22nd",
      },
      {
        title: "CAMP END & PICKUP",
        data: "Saturday, December 28th",
      },
      {
        title: "WHERE",
        data: "Camp Cullen, Trinity, TX",
      },
      {
        title: "WHO",
        data: "Children from Grades 1 - 12",
      },
      {
        title: "CAMP FEES",
        data: "$280 for first child\n$250 for every subsequent child after",
      },
    ],
  },
  retreat: {
    id: "retreat",
    enabled: false,
    camp_title: "RETREAT 2025",
    registration_information: "",
    registration_link: null,
    informational_blobs: [
      {
        title: "RETREAT START & DROPOFF",
        data: "Friday, September 1s",
      },
      {
        title: "RETREAT END & PICKUP",
        data: "Monday, September 4th",
      },
      {
        title: "WHERE",
        data: "CYJ Retreat Center, Woodcreek, TX",
      },
      {
        title: "WHO",
        data: "Rising college juniors and above",
      },
      {
        title: "CAMP FEES",
        data: "$250 for college students\n$300 for working professionals",
      },
      {
        title: "CONTACT",
        data: "texassikhretreat@gmail.com",
      },
    ],
  },
};
