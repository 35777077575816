import { useState } from "react";
import { items } from "../utils/NavigationItems";
import { Button, HStack, Link, Text } from "@chakra-ui/react";
import colors from "../utils/color_codes.js";

export default function Navigation() {
  return (
    <>
      <HStack
        spacing={"1rem"}
        bg={colors.mainBackgroundColor}
        justify={"left"}
        style={{
          paddingRight: "1rem",
          height: "5vh",
          overflowY: "auto",
          paddingLeft: "5rem",
        }}
      >
        {items.map((item) => {
          return item.enabled ? (
            <Link href={`${item.href_field}`}>
              <Button
                _hover={{
                  textColor: "gray",
                  cursor: "pointer",
                  opacity: 0.7,
                }}
                bg={colors.mainBackgroundColor}
                textColor={colors.textColor}
                style={{ minWidth: "5rem" }}
              >
                <Text fontSize={"xl"} as="b">
                  {item.display_name}
                </Text>
              </Button>
            </Link>
          ) : (
            ""
          );
        })}
      </HStack>
    </>
  );
}
