export const items = [
  {
    name: "home",
    id: "main_page",
    display_name: "HSYC",
    enabled: true,
    href_field: "/",
  },
  {
    name: "winterCamp",
    id: "wintercamp",
    display_name: "Winter Camp",
    enabled: true,
    href_field: "#winter-camp",
  },
  {
    name: "retreat",
    id: "retreat",
    display_name: "Retreat",
    enabled: false,
    href_field: "#retreat",
  },
  {
    name: "media",
    id: "media",
    display_name: "Media",
    enabled: true,
    href_field: "#media",
  },
  {
    name: "campVenue",
    id: "campvenue",
    display_name: "Camp Venue",
    enabled: true,
    href_field: "#camp-venue",
  },
  {
    name: "contact",
    id: "contact",
    display_name: "Contact",
    enabled: true,
    href_field: "#contact-section",
  },
  {
    name: "register",
    id: "register",
    display_name: "Register",
    enabled: true,
    href_field: "/register",
  },
];

export const admin_navigation_items = [
  {
    name: "home",
    id: "main_page",
    display_name: "HSYC",
    enabled: true,
    href_field: "/",
  },
  {
    name: "campers",
    id: "campers",
    display_name: "Registered Campers",
    enabled: true,
    href_field: "/admin/campers",
  },
  {
    name: "flights",
    id: "flights",
    display_name: "Flight Information",
    enabled: true,
    href_field: "/admin/flights",
  },
  // {
  //   name: "media",
  //   id: "media",
  //   display_name: "Media",
  //   enabled: true,
  //   href_field: "#media",
  // },
  // {
  //   name: "campVenue",
  //   id: "campvenue",
  //   display_name: "Camp Venue",
  //   enabled: true,
  //   href_field: "#camp-venue",
  // },
  // {
  //   name: "contact",
  //   id: "contact",
  //   display_name: "Contact",
  //   enabled: true,
  //   href_field: "#contact-section",
  // },
  // {
  //   name: "register",
  //   id: "register",
  //   display_name: "Register",
  //   enabled: true,
  //   href_field: "/register",
  // },
];
