import { useState, useEffect } from "react";
import {
  Select,
  HStack,
  Input,
  Flex,
  Heading,
  Box,
  Spacer,
  ButtonGroup,
  Button,
  Container,
  Text,
  Center,
  FormControl,
  FormLabel,
  Stack,
  Textarea,
  SimpleGrid,
  Badge,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import AdminNavigation from "../components/AdminNavigation";
import { toast } from "react-hot-toast";

export default function SingleCamperPage({}) {
  const navigate = useNavigate();
  const { camper_id } = useParams();
  const [camper, setCamper] = useState(null);
  const [campers, setCampers] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");

  const showSuccess = () => toast.success("Updated Payment Information!");
  const showLoading = () => toast.loading("Updating...");
  const showSuccessPaymentUpdate = () =>
    toast.success("Updated Payment Method!");
  const showSuccessToast = () => toast.success("Updated Information!");
  const getCamper = () => {
    axios
      .get(`https://api.houstonsikhyouthcamp.org/camper/${camper_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setCamper(res.data.data);
      });
  };
  const getCampers = () => {
    axios
      .get(`https://api.houstonsikhyouthcamp.org/camper`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setCampers(res.data.data);
      });
  };
  useEffect(() => {
    getCamper();
    getCampers();
  }, [camper_id]);

  return (
    <>
      <Box>
        <AdminNavigation />
        {/* <Box as="section" py={{ base: "4", md: "8" }}>
          <Heading as={"h2"} size={"2xl"} color="black">
            {camper?.firstName} {camper?.lastName}
          </Heading>
        </Box> */}
        <Container maxW={"70%"} mt={"1em"} mb={"1em"}>
          {/* <Flex minWidth="max-content" alignItems="center" gap="2"> */}
          <SimpleGrid columns={[1, 2, 1]} w={"100%"}>
            <Box p="2">
              <Heading size="xl">
                {camper?.firstName} {camper?.lastName}
              </Heading>
            </Box>
            <Spacer />
            <Box>
              <Stack direction="row" my={".5rem"} ml={"0.5rem"}>
                <Badge
                  colorScheme={
                    camper?.camperFlying === "yes" ? "red" : "purple"
                  }
                >
                  {camper?.camperFlying === "yes"
                    ? "Flying In"
                    : "Local Camper"}
                </Badge>
                <Badge
                  colorScheme={camper?.checkedIn ? "green" : "red"}
                  _hover={{ cursor: "pointer" }}
                  onClick={() => {
                    axios
                      .put(
                        "https://api.houstonsikhyouthcamp.org/checkin",
                        {
                          camper_information: { camper },
                          camper_checked_in: !camper.checkedIn,
                          camper_id: camper._id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                              "token"
                            )}`,
                          },
                        }
                      )
                      .then((res) => {
                        // callback_request(true);
                        showSuccessToast();
                        getCamper();
                      });
                  }}
                >
                  {camper?.checkedIn ? "Checked In" : "Pending Check In"}
                </Badge>
              </Stack>
              <SimpleGrid
                columns={[1, null, 3]}
                spacing={"3"}
                w={"100%"}
                ml={"0.rem"}
              >
                <Button
                  // minW={"10rem"}
                  backgroundColor={camper?.paid ? "#9ADE7B" : "#FA7070"}
                  onClick={() => {
                    // setOpenForm(true);
                    axios
                      .put(
                        "https://api.houstonsikhyouthcamp.org/camper",
                        {
                          camper_information: { camper },
                          camper_paid: !camper.paid,
                          camper_id: camper._id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                              "token"
                            )}`,
                          },
                        }
                      )
                      .then((res) => {
                        // callback_request(true);
                        showSuccess();
                        getCamper();
                      });
                  }}
                >
                  {camper?.paid ? "Payment Completed" : "Payment Pending"}
                </Button>
                <Button
                  // minW={"10rem"}
                  backgroundColor={
                    camper?.status === "Attending" ? "#9ADE7B" : "#FA7070"
                  }
                  onClick={() => {
                    axios
                      .put(
                        "https://api.houstonsikhyouthcamp.org/attendance",
                        {
                          camper_information: { camper },
                          camper_status:
                            camper?.status === "Attending"
                              ? "Not Attending"
                              : "Attending",
                          camper_id: camper._id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                              "token"
                            )}`,
                          },
                        }
                      )
                      .then((res) => {
                        // callback_request(true);
                        showSuccessToast();
                        getCamper();
                      });
                  }}
                >
                  {camper?.status}
                </Button>
              </SimpleGrid>
              <Flex color="white" h={"100%"} m={"1em"}>
                <Text w="25%" color="black" as="b">
                  Payment Method
                </Text>
                {/* <Box bg="blue.500" size="150px">
                <Text>Box 2</Text>
              </Box> */}
                <Box w="100%" p={4} color="white">
                  <HStack spacing={"1rem"}>
                    <FormControl color={"black"} maxW={"30%"}>
                      {/* <FormLabel size={"md"}>Payment Method</FormLabel> */}
                      <Input
                        placeholder={
                          camper?.paymentMethod
                            ? camper.paymentMethod
                            : "Enter Payment Method"
                        }
                        value={paymentMethod}
                        textColor={"black"}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                      />
                    </FormControl>
                    <Button
                      onClick={() => {
                        axios
                          .put(
                            "https://api.houstonsikhyouthcamp.org/camper",
                            {
                              camper_information: { camper },
                              // camper_paid: !camper.paid,
                              camper_id: camper._id,
                              camper_payment_method:
                                paymentMethod === "" ? "" : paymentMethod,
                            },
                            {
                              headers: {
                                Authorization: `Bearer ${localStorage.getItem(
                                  "token"
                                )}`,
                              },
                            }
                          )
                          .then((res) => {
                            // callback_request(true);
                            showSuccessPaymentUpdate();
                            getCamper();
                          });
                      }}
                    >
                      Submit
                    </Button>
                    <p>{camper?.paymentMethod} somethin</p>
                  </HStack>
                </Box>

                {/* <Box w="100%" p={4} color="white"></Box> */}
              </Flex>
            </Box>
          </SimpleGrid>
          {/* </Flex> */}
          <Box minH={"25vh"} m={"1em"}>
            <Flex color="white" h={"100%"}>
              <Text w="25%" color="black" as="b">
                Basic Information
              </Text>
              {/* <Box bg="blue.500" size="150px">
              <Text>Box 2</Text>
            </Box> */}
              <Box w="100%" p={4} color="white">
                {/* This is the Box */}
                <Stack spacing={"1"} direction={["column", "row"]}>
                  <FormControl color={"black"}>
                    <FormLabel>First Name</FormLabel>
                    <Input
                      value={camper?.firstName}
                      disabled
                      textColor={"black"}
                    />
                  </FormControl>
                  <FormControl color={"black"}>
                    <FormLabel>Last Name</FormLabel>
                    <Input value={camper?.lastName} disabled />
                  </FormControl>
                  <FormControl color={"black"}>
                    <FormLabel>Gender</FormLabel>
                    <Input
                      value={camper?.gender}
                      disabled
                      textColor={"black"}
                    />
                  </FormControl>
                </Stack>
                <Stack spacing={"1"} direction={["column", "row"]} mt={"2em"}>
                  <FormControl color={"black"}>
                    <FormLabel>Birthday</FormLabel>
                    <Input
                      value={`${camper?.month}-${camper?.day}-${camper?.year}`}
                      disabled
                      textColor={"black"}
                    />
                  </FormControl>
                  <FormControl color={"black"}>
                    <FormLabel>Grade</FormLabel>
                    <Input value={camper?.grade} disabled />
                  </FormControl>
                  <FormControl color={"black"}>
                    <FormLabel>Shirt Size</FormLabel>
                    <Input
                      value={camper?.shirtSize}
                      disabled
                      textColor={"black"}
                    />
                  </FormControl>
                </Stack>
              </Box>
            </Flex>
            {/* </Stack> */}
          </Box>
          <Box minH={"25vh"} m={"1em"}>
            <Flex color="white" h={"100%"}>
              <Text w="25%" color="black" as="b">
                Medical Information
              </Text>
              <Box w="100%" p={4} color="white">
                {/* This is the Box */}
                <Stack spacing={"1"} direction={["column", "row"]}>
                  <FormControl color={"black"}>
                    <FormLabel>Doctor's Name</FormLabel>
                    <Input
                      value={camper?.doctorsName}
                      disabled
                      textColor={"black"}
                    />
                  </FormControl>
                  <FormControl color={"black"}>
                    <FormLabel>Doctor's Number</FormLabel>
                    <Input value={camper?.doctorsNumber} disabled />
                  </FormControl>
                </Stack>
                <Stack spacing={"1"} direction={["column", "row"]} mt={"2em"}>
                  <FormControl color={"black"}>
                    <FormLabel>Medical Information</FormLabel>
                    <Textarea value={camper?.medicalConditions} disabled />
                  </FormControl>
                  <FormControl color={"black"}>
                    <FormLabel>Regular Medications</FormLabel>
                    <Textarea
                      value={camper?.regularMedications}
                      disabled
                      textColor={"black"}
                    />
                  </FormControl>
                </Stack>
              </Box>
            </Flex>
          </Box>
          <Box minH={"25vh"} m={"1em"}>
            <Flex color="white" h={"100%"}>
              <Text w="25%" color="black" as="b">
                Guardian Information
              </Text>
              <Box w="100%" p={4} color="white">
                {/* This is the Box */}
                <Stack spacing={"1"} direction={["column", "row"]}>
                  <FormControl color={"black"}>
                    <FormLabel>Mother's Name</FormLabel>
                    <Input
                      value={camper?.motherName}
                      disabled
                      textColor={"black"}
                    />
                  </FormControl>
                  <FormControl color={"black"}>
                    <FormLabel>Mother's Number</FormLabel>
                    <Input value={camper?.motherNumber} disabled />
                  </FormControl>
                </Stack>
                <Stack spacing={"1"} direction={["column", "row"]} mt={"2em"}>
                  <FormControl color={"black"}>
                    <FormLabel>Father's Number</FormLabel>
                    <Input value={camper?.motherNumber} disabled />
                  </FormControl>
                  <FormControl color={"black"}>
                    <FormLabel>Father's Number</FormLabel>
                    <Input
                      value={camper?.fatherName}
                      disabled
                      textColor={"black"}
                    />
                  </FormControl>
                </Stack>
                <Stack spacing={"1"} direction={["column", "row"]} mt={"2em"}>
                  <FormControl color={"black"}>
                    <FormLabel>Address</FormLabel>
                    <Input
                      value={`${camper?.streetAddress} ${camper?.city} ${camper?.state}, ${camper?.zip}`}
                      disabled
                    />
                  </FormControl>
                  {/* <FormControl color={"black"}>
                    <FormLabel>Father's Number</FormLabel>
                    <Input
                      value={camper?.fatherName}
                      disabled
                      textColor={"black"}
                    />
                  </FormControl> */}
                </Stack>
              </Box>
            </Flex>
          </Box>
        </Container>
      </Box>
    </>
  );
}
