import { useState } from "react";
import { Form, useForm, useFormContext, Controller } from "react-hook-form";
import {
  FormControl,
  FormControlOptions,
  FormLabel,
  FormHelperText,
  FormErrorIcon,
  FormErrorMessage,
  Container,
  Text,
  Link,
  Button,
  Input,
  HStack,
  Select,
  VStack,
  Textarea,
  RadioGroup,
  Radio,
  Heading,
  Stack,
  Box,
} from "@chakra-ui/react";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { states } from "../utils/RegistrationUtils";
export default function ParentInfoSection() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useFormContext();
  const [date, setDate] = useState(new Date());
  const watchFlying = watch("guardianInformation.camperFlying", false);
  // const watchFlying =  watch((data, { name, type }) => console.log(data, name, type))
  return (
    <>
      <Container maxW="80%">
        <div style={{ width: "45%", marginBottom: "0rem" }}>
          <Heading as="h1" size={"xl"} style={{ float: "left" }}>
            Parent Information
          </Heading>
        </div>

        <Stack
          width={"100%"}
          alignItems="end"
          pt={"1rem"}
          direction={["column", "row"]}
        >
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input
              placeholder="Email"
              {...register("guardianInformation.email", {
                required: true,
                maxLength: 35,
              })}
              type="email"
            />
          </FormControl>
          <FormControl>
            <FormLabel>Mother's Name</FormLabel>
            <Input
              placeholder="Mother's Name"
              {...register("guardianInformation.motherName", {
                required: true,
                maxLength: 50,
              })}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Mother's Phone Number</FormLabel>
            <Input
              placeholder="Mother's Phone"
              {...register("guardianInformation.motherNumber", {
                required: true,
                maxLength: 15,
              })}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Father's Name</FormLabel>
            <Input
              placeholder="Father's Name"
              {...register("guardianInformation.fatherName", {
                required: true,
                maxLength: 50,
              })}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Father's Phone Number</FormLabel>
            <Input
              placeholder="Father's Phone"
              {...register("guardianInformation.fatherNumber", {
                required: true,
                maxLength: 15,
              })}
            />
          </FormControl>
        </Stack>

        <Stack mt={"1rem"} direction={["column", "row"]}>
          <FormControl>
            <FormLabel>Home Street Address</FormLabel>
            <Input
              placeholder="Street Address"
              {...register("guardianInformation.streetAddress", {
                required: true,
                maxLength: 50,
              })}
            />
          </FormControl>

          <FormControl>
            <FormLabel>City</FormLabel>
            <Input
              type="text"
              placeholder="City"
              {...register("guardianInformation.city", {
                required: true,
                maxLength: 50,
              })}
            />
          </FormControl>

          <FormControl>
            <FormLabel>State</FormLabel>
            <Select
              placeholder="Select State"
              _hover={{ cursor: "pointer" }}
              {...register("guardianInformation.state", {
                required: true,
              })}
            >
              {states.map((state) => {
                return <option value={state}>{state}</option>;
              })}
            </Select>
          </FormControl>

          <FormControl w={"30%"}>
            <FormLabel>Zip Code</FormLabel>
            <Input
              type="number"
              placeholder="ZIP"
              {...register("guardianInformation.zip", {
                required: true,
                maxLength: 8,
              })}
            />
          </FormControl>
        </Stack>

        <FormControl mt={"1rem"}>
          <FormLabel>Is your camper arriving by plane?</FormLabel>
          {/* <RadioGroup defaultValue="No" {...register("camperFlying")}>
            <HStack spacing="24px">
            <Radio value="Yes">Yes</Radio>
            <Radio value="No">No</Radio>
            </HStack>
          </RadioGroup> */}
          <Controller
            render={({ field }) => (
              <RadioGroup aria-label="camperFlying" {...field}>
                <Stack spacing="24px" direction={["column", "row"]}>
                  <Radio value="yes" label="Yes">
                    {" "}
                    Yes{" "}
                  </Radio>
                  <Radio value="no" label="No">
                    No
                  </Radio>
                </Stack>
              </RadioGroup>
            )}
            name="guardianInformation.camperFlying"
            control={control}
            rules={{ required: true }}
          />
          {/* {console.log(watchFlying)} */}
          {watchFlying === "yes" && (
            <>
              <Text fontSize="md" as="b" color={"lightslategray"}>
                If you have the campers' flight information please enter it now,
                if not, we'll contact you soon for your flight information!
              </Text>
              <Stack direction={["column", "row"]}>
                <FormControl>
                  <FormLabel>Arrival Flight Information</FormLabel>
                  <Input
                    type="text"
                    placeholder="Arrival Flight Number"
                    {...register(
                      "guardianInformation.flightInformation.arrivialInformation.arrivalFlightNumber",
                      {
                        maxLength: 50,
                        shouldUnregister: true,
                      }
                    )}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Arrival Flight Date</FormLabel>
                  <Input
                    type="text"
                    placeholder="Arrival Flight Date"
                    {...register(
                      "guardianInformation.flightInformation.arrivialInformation.arrivalDate",
                      {
                        maxLength: 50,
                        shouldUnregister: true,
                      }
                    )}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Arrival Flight Time</FormLabel>
                  <Input
                    type="text"
                    placeholder="Arrival Flight Time"
                    {...register(
                      "guardianInformation.flightInformation.arrivialInformation.arrivalTime",
                      {
                        maxLength: 50,
                        shouldUnregister: true,
                      }
                    )}
                  />
                </FormControl>
              </Stack>
              <Stack direction={["column", "row"]}>
                <FormControl>
                  <FormLabel>Departure Flight Information</FormLabel>
                  <Input
                    type="text"
                    placeholder="Departure Flight Number"
                    {...register(
                      "guardianInformation.flightInformation.departureInformation.departureFlightNumber",
                      {
                        maxLength: 50,
                        shouldUnregister: true,
                      }
                    )}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Departure Flight Date</FormLabel>
                  <Input
                    type="text"
                    placeholder="Departure Flight Date"
                    {...register(
                      "guardianInformation.flightInformation.departureInformation.departureDate",
                      {
                        maxLength: 50,
                        shouldUnregister: true,
                      }
                    )}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Departure Flight Time</FormLabel>
                  <Input
                    type="text"
                    placeholder="Departure Flight Time"
                    {...register(
                      "guardianInformation.flightInformation.departureInformation.departureTime",
                      {
                        maxLength: 50,
                        shouldUnregister: true,
                      }
                    )}
                  />
                </FormControl>
              </Stack>
            </>
          )}
        </FormControl>
        <FormControl mt={"1rem"}>
          <FormLabel>Select Payment Method</FormLabel>
          <Controller
            render={({ field }) => (
              <RadioGroup aria-label="camperPayment" {...field}>
                <Stack spacing="24px" direction={["column", "row"]}>
                  <Radio value="check" label="check">
                    Check (Made out to Sikh Center of Gulf Coast)
                  </Radio>
                  <Radio value="cash" label="cash">
                    Cash
                  </Radio>
                  <Radio value="zelle" label="zelle">
                    Zelle (houstonsikhyouthcamp@gmail.com)
                  </Radio>
                  <Radio value="venmo" label="venmo">
                    Venmo (@HoustonSikhYouthCamp)
                  </Radio>
                </Stack>
              </RadioGroup>
            )}
            name="guardianInformation.camperPayment"
            control={control}
            rules={{ required: true }}
          />
        </FormControl>
      </Container>
    </>
  );
}
