import { useState } from "react";
import { Form, useForm, useFormContext } from "react-hook-form";
import {
  FormControl,
  FormControlOptions,
  Checkbox,
  FormLabel,
  FormHelperText,
  FormErrorIcon,
  FormErrorMessage,
  Container,
  Text,
  Link,
  Button,
  Input,
  HStack,
  Select,
  VStack,
  Textarea,
  RadioGroup,
  Radio,
  Heading,
} from "@chakra-ui/react";
export default function ReigstrationAgreement() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useFormContext();
  const [date, setDate] = useState(new Date());

  return (
    <>
      {/* {console.log(error)} */}
      <Container maxW="80%" alignSelf={"start"} pt={"2rem"}>
        <div style={{ width: "100%", marginBottom: "0rem" }}>
          <Heading as="h1" size={"xl"} style={{ float: "left" }}>
            Camp Policies and Agreement
          </Heading>
        </div>
        {/* <FormControl>
          <FormLabel>Mother's Name</FormLabel>
          <Input placeholder="Mother's Name" {...register("motherName")} />
        </FormControl> */}
        <VStack spacing={"0.5rem"} minW={"100%"} pt={"1rem"}>
          <FormControl>
            <HStack>
              {/* <input
              name="January"
              type="checkbox"
              value={"testing"}
              {...register("January")}
              className="mx-3"
            /> */}
              <Checkbox
                textAlign={"left"}
                {...register("agreement.argreement_section1", {
                  required: true,
                })}
              >
                I understand that the failure of a camper to maintain such
                standards or failure to properly participate in camp activities
                or maintain befitting discipline and conduct may result in
                suitable and reasonable punishment, including expulsion from the
                camp, at my expense without any refund of fees paid.
              </Checkbox>
              {/* <FormLabel>
              
            </FormLabel> */}
            </HStack>
          </FormControl>
          <FormControl>
            <HStack>
              <Checkbox
                textAlign={"left"}
                {...register("agreement.argreement_section2", {
                  required: true,
                })}
              >
                I hereby waive any and all claims, against the Sikh Center and
                its volunteers, arising to take whatever action they feel is
                warranted under the circumstances in regard to my child(ren)’s
                health and safety, including securing medical treatment (at my
                own or my child[ren]’s expense). I further release any of these
                persons from any liability for such decisions or actions, which
                may be taken on my behalf.
              </Checkbox>
              {/* <FormLabel>
              
            </FormLabel> */}
            </HStack>
          </FormControl>
          <FormControl>
            <HStack>
              <Checkbox
                textAlign={"left"}
                {...register("agreement.argreement_section3", {
                  required: true,
                })}
              >
                I understand that campers must respect the facility and leave it
                in the way they found it at the beginning of the camp. I will be
                liable to pay a fee as set forth by the organizers if (any of)
                my child(ren) bring(s) any damage to the room or facility that
                my child(ren) will be using. All of the campers in each room are
                all responsible for that room. The damages fee will be divided
                among all of the campers in that particular room in question
              </Checkbox>
              {/* <FormLabel>
              
            </FormLabel> */}
            </HStack>
          </FormControl>
          <FormControl>
            <HStack>
              <Checkbox
                textAlign={"left"}
                {...register("agreement.argreement_section4", {
                  required: true,
                })}
              >
                If cancellation occurs after Dec 18th, no refunds will be
                offered. Before Dec 18th, 50% of the registration fee will be
                returned.
              </Checkbox>
              {/* <FormLabel>
              
            </FormLabel> */}
            </HStack>
          </FormControl>
          <FormControl>
            <HStack alignItems={"start"}>
              <Checkbox
                textAlign={"left"}
                {...register("agreement.argreement_section5", {
                  required: true,
                })}
              >
                During the stay at Houston Sikh Youth Camp, various volunteer
                doctors will be assisting my child(ren) with various minor
                ailments. I have fully disclosed all relevant allergies or
                medical conditions to the Houston Sikh Youth Camp. I will not
                hold the doctors professionally or personally liable for the
                administration of medicine or any health care during the
                duration of the camp.
              </Checkbox>
              {/* <FormLabel>
              
            </FormLabel> */}
            </HStack>
          </FormControl>
          <FormControl>
            <HStack>
              <Checkbox
                textAlign={"left"}
                {...register("agreement.argreement_section6", {
                  required: true,
                })}
              >
                I hereby consent that my child(ren)’s picture/video may be taken
                and used to promote the Sikh Center and Houston Sikh Youth Camp,
                and/or its programs.
              </Checkbox>
              {/* <FormLabel>
              
            </FormLabel> */}
            </HStack>
          </FormControl>
        </VStack>
      </Container>
    </>
  );
}
