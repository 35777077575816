import {
  Box,
  SimpleGrid,
  Container,
  Heading,
  Stack,
  HStack,
  VStack,
  Text,
} from "@chakra-ui/react";
import AdminNavigation from "../components/AdminNavigation";
import { another_test_response } from "../utils/sample_data";
import FlightInformationCard from "../components/FlightInformationCard";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function AdminFlightView() {
  const [flights, setFlights] = useState([]);
  const [campers, setCampers] = useState([]);
  const navigate = useNavigate();
  const grabFlights = () => {
    axios
      .get("https://api.houstonsikhyouthcamp.org/camper", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        // console.log(res.data.data);
        setFlights(res.data.data);
        setCampers(res.data.data);
      })
      .catch((err) => {
        navigate("/admin/login");
      });
  };
  useEffect(() => {
    grabFlights();
  }, []);
  return (
    <>
      <AdminNavigation />
      <Box>
        {/* <p>Flights here</p> */}
        <Heading
          as={"h3"}
          size={"2xl"}
          color="black"
          m={"1rem"}
          textAlign={"center"}
        >
          Flight Information
        </Heading>
        <Container maxW="70%" mb={"2rem"}>
          <SimpleGrid columns={[1, null, 3]} spacing={5}>
            {campers.map((camper) => {
              if (camper.camperFlying === "yes") {
                console.log(camper.flightInformation);
                return (
                  <FlightInformationCard
                    camperFlightInfo={camper.flightInformation}
                    camperFirstName={camper.firstName}
                    camperLastName={camper.lastName}
                    emailContact={camper.email}
                  />
                );
              }
            })}
            {/* <FlightInformationCard /> */}
            {/* <Box bg="tomato" minH="30vh" rounded={"lg"}></Box>
            <Box bg="tomato" minH="30vh" rounded={"lg"}></Box>
            <Box bg="tomato" minH="30vh" rounded={"lg"}></Box>
            <Box bg="tomato" minH="30vh" rounded={"lg"}></Box> */}
          </SimpleGrid>
        </Container>
      </Box>
    </>
  );
}
