import Navigation from "../components/Navigation";
import ActionSection from "../components/ActionSection";
import MediaSection from "../components/MediaSection";
import CampInformationSection from "../components/CampInformationalSection";
import Announcements from "../components/Announcements";
import ContactSection from "../components/ContactSection";
import Footer from "../components/Footer";
import { Container, Alert, AlertIcon } from "@chakra-ui/react";
import colors from "../utils/color_codes";

export default function HomePage() {
  return (
    <>
      {/* <Alert status="error">
        <AlertIcon />
        <p>
          Registration Closes on{" "}
          <span style={{ fontWeight: "bold" }}>12/18/2023 at 11:59pm CST</span>
        </p>
      </Alert> */}
      <Navigation />
      <Container maxW={"100%"} p={"0"} backgroundColor={colors.textColor}>
        <ActionSection />
        <CampInformationSection section_name={"wintercamp"} />
        <Announcements />
        <MediaSection />
        <ContactSection />
        <Footer />
      </Container>
    </>
  );
}
