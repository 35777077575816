import { useState } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Container,
  Input,
  HStack,
  Select,
  VStack,
  Textarea,
  Heading,
  Stack,
} from "@chakra-ui/react";
export default function CamperInfoSection({
  section_number,
  callback_function,
}) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useFormContext({ shouldUnregister: true });
  return (
    <>
      <Container maxW="80%" mt={"1rem"}>
        <div style={{ width: "55%" }}>
          <Heading as="h1" size={"xl"} style={{ float: "left" }}>
            Camper {section_number + 1} Information
          </Heading>
        </div>

        <Stack
          width={"100%"}
          alignItems="start"
          pt={"1rem"}
          direction={["column", "row"]}
        >
          <FormControl>
            <FormLabel>First Name</FormLabel>
            <Input
              type=""
              placeholder="First Name"
              {...register(`campersInformation[${section_number}].firstName`, {
                required: "This is required",
                maxLength: 20,
              })}
            />
            {/* {console.log(errors.campersInformation)}
            {console.log(section_number)} */}
          </FormControl>

          <FormControl>
            <FormLabel>Last Name</FormLabel>
            <Input
              type=""
              placeholder="Last Name"
              {...register(`campersInformation[${section_number}].lastName`, {
                required: "This is required",
                maxLength: 20,
              })}
            />
          </FormControl>
          {/* <FormControl> */}
          <VStack>
            <HStack>
              <FormControl>
                <FormLabel textAlign={"center"}>Month</FormLabel>
                <Input
                  type={"number"}
                  placeholder="MM"
                  {...register(`campersInformation[${section_number}].month`, {
                    required: "This is required",
                    maxLength: 2,
                    min: 1,
                    max: 12,
                  })}
                />
              </FormControl>
              <FormControl>
                <FormLabel textAlign={"center"}>Day</FormLabel>
                <Input
                  type={"number"}
                  placeholder="DD"
                  {...register(`campersInformation[${section_number}].day`, {
                    required: "This is required",
                    maxLength: 2,
                    min: 1,
                    max: 31,
                  })}
                />
              </FormControl>
              <FormControl>
                <FormLabel textAlign={"center"}>Year</FormLabel>
                <Input
                  type={"number"}
                  placeholder="YYYY"
                  {...register(`campersInformation[${section_number}].year`, {
                    required: "This is required",
                    maxLength: 4,
                    minLength: 4,
                    min: 1990,
                    max: 2020,
                  })}
                />
              </FormControl>
            </HStack>
            <FormLabel>Date of Birth</FormLabel>
          </VStack>
        </Stack>
        <Stack mt={"1rem"} direction={["column", "row"]}>
          <FormControl>
            <FormLabel>Gender</FormLabel>
            <Select
              placeholder="Select"
              _hover={{ cursor: "pointer" }}
              {...register(`campersInformation[${section_number}].gender`, {
                required: "This is required",
                maxLength: 20,
              })}
            >
              <option value="Singh">Singh</option>
              <option value="Kaur">Kaur</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Shirt Size</FormLabel>
            <Select
              placeholder="Select"
              _hover={{ cursor: "pointer" }}
              {...register(`campersInformation[${section_number}].shirtSize`, {
                required: "This is required",
                maxLength: 20,
              })}
            >
              <option value="Adult S">Adult S</option>
              <option value="Adult M">Adult M</option>
              <option value="Adult L">Adult L</option>
              <option value="Adult XL">Adult XL</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Grade</FormLabel>
            <Select
              placeholder="Select"
              _hover={{ cursor: "pointer" }}
              {...register(`campersInformation[${section_number}].grade`, {
                required: "This is required",
                maxLength: 20,
              })}
            >
              <option value="Grade 1">1st</option>
              <option value="Grade 2">2nd</option>
              <option value="Grade 3">3rd</option>
              <option value="Grade 4">4th</option>
              <option value="Grade 5">5th</option>
              <option value="Grade 6">6th</option>
              <option value="Grade 7">7th</option>
              <option value="Grade 8">8th</option>
              <option value="Grade 9">9th</option>
              <option value="Grade 10">10th</option>
              <option value="Grade 11">11th</option>
              <option value="Grade 12">12th</option>
            </Select>
            {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
          </FormControl>
        </Stack>

        <Stack maxW={"100%"} mt={"1rem"} direction={["column", "row"]}>
          <FormControl>
            <FormLabel>Medical Conditions/Allergies</FormLabel>
            <Textarea
              placeholder="Camper Medical Condition"
              size="sm"
              rounded={"md"}
              {...register(
                `campersInformation[${section_number}].medicalConditions`,
                {
                  maxLength: 40000,
                }
              )}
            />
            <FormHelperText style={{ float: "left" }}>
              We'll never share your information with outside parties.
            </FormHelperText>
          </FormControl>
          {/* require only if the above area is filled */}
          <FormControl>
            <FormLabel>Please list any regular medications</FormLabel>
            <Textarea
              placeholder="Regular Medication"
              size="sm"
              rounded={"md"}
              {...register(
                `campersInformation[${section_number}].regularMedications`,
                {
                  maxLength: 40000,
                }
              )}
            />
            <FormHelperText style={{ float: "left" }}>
              We'll never share your information with outside parties.
            </FormHelperText>
          </FormControl>
        </Stack>

        <Stack
          maxW={"100%"}
          align="start"
          mt={"1rem"}
          direction={["column", "row"]}
        >
          <FormControl>
            <FormLabel>Camper's Doctor Name</FormLabel>
            <Input
              type=""
              placeholder="Doctor Name"
              {...register(
                `campersInformation[${section_number}].doctorsName`,
                {
                  required: "This is required",
                  maxLength: 50,
                }
              )}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Doctor's Phone Number</FormLabel>
            <Input
              type="number"
              placeholder="Phone Number"
              {...register(
                `campersInformation[${section_number}].doctorsNumber`,
                {
                  required: "This is required",
                  maxLength: 10,
                }
              )}
            />
            <FormHelperText style={{ float: "left" }}>
              ex. 3015559999
            </FormHelperText>
          </FormControl>
        </Stack>
      </Container>
    </>
  );
}
