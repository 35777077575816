import {
  Container,
  Heading,
  Text,
  Divider,
  HStack,
  Link,
  Center,
  VStack,
  Box,
} from "@chakra-ui/react";
import { useState, useCallback } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import { FaExternalLinkAlt } from "react-icons/fa";
import colors from "../utils/color_codes";

const containerStyle = {
  width: "50vh",
  height: "25vh",
};

const center = {
  lat: 30.920303,
  lng: -95.302834,
};

export default function Announcements() {
  //     YMCA CAMP CULLEN
  // 460 Cullen Loop
  // Trinity, Texas 75862

  // YMCA Camp Cullen website

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCxiMK-2j5-alifwo4t2HfotMQxSoheOLs",
  });

  const [map, setMap] = useState(null);

  const onLoad = useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <>
      <Container
        centerContent
        bg={colors.textColor}
        maxW="100%"
        // p={"2em"}
        h={"50vh"}
        id="camp-venue"
        textColor={"#333333"}
      >
        <Heading as={"h3"} size={"xl"} mb={"0rem"}>
          CAMP VENUE
        </Heading>
        <Divider mb={"1rem"} maxW="50%" height="0em" />
        <Box>
          {/* <HStack> */}
          <VStack>
            <Heading as={"h3"} size={"lg"} mb={"0.25rem"}>
              <Link href="https://ymcacampcullen.org/" isExternal>
                YMCA CAMP CULLEN
              </Link>
            </Heading>
            <Heading as={"h3"} size={"md"} mb={"1rem"}>
              <Link href="https://maps.app.goo.gl/SPjrrhDi9ajNhMHaA" isExternal>
                460 Cullen Loop Trinity, Texas 75862
              </Link>
            </Heading>
          </VStack>
          <Box borderRadius={"md"}>
            <LoadScript googleMapsApiKey="AIzaSyCxiMK-2j5-alifwo4t2HfotMQxSoheOLs">
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15}
                onLoad={onLoad}
                onUnmount={onUnmount}
              >
                {/* <Marker lat={center.lat} lng={center.lng} /> */}
                {/* Child components, such as markers, info windows, etc. */}
              </GoogleMap>
            </LoadScript>
          </Box>
          {/* </HStack> */}
        </Box>
      </Container>
    </>
  );
}
