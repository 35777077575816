import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  Stack,
  Text,
  InputGroup,
  Image,
  //   useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
  const [errorStatus, setErrorStatus] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = (data) => {
    // console.log(data);
    axios
      .post("https://api.houstonsikhyouthcamp.org/login", { data })
      .then((res) => {
        // console.log(res);
        if (res.status === 201) {
          localStorage.setItem("token", res.data.token);
          navigate("/admin/campers");
        }
      })
      .catch((err) => {
        setErrorStatus(true);
      });
  };
  return (
    <Container
      maxW="lg"
      py={{
        base: "12",
        md: "24",
      }}
      px={{
        base: "0",
        sm: "8",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing="8">
          <Box
            py={{
              base: "0",
              sm: "8",
            }}
            px={{
              base: "4",
              sm: "10",
            }}
            bg={{
              base: "transparent",
              sm: "bg.surface",
            }}
            boxShadow={{
              base: "none",
              sm: "md",
            }}
            borderRadius={{
              base: "none",
              sm: "xl",
            }}
          >
            <Image
              src="/camplogo.png"
              alt="Houston Camp Logo"
              borderRadius="full"
              boxSize="5rem"
              marginRight={"auto"}
              marginLeft={"auto"}
            />
            <Heading
              size={{
                base: "xs",
                md: "sm",
              }}
            >
              Log in to your account
            </Heading>
            <Stack spacing="6">
              <Stack spacing="5">
                <FormControl>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input id="email" type="email" {...register("username")} />
                </FormControl>
                {/* <PasswordField /> */}
                <FormControl>
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <InputGroup>
                    <Input
                      id="password"
                      name="password"
                      // type={isOpen ? "text" : "password"}
                      type={"password"}
                      autoComplete="current-password"
                      required
                      // {...props}
                      {...register("password")}
                    />
                  </InputGroup>
                </FormControl>
              </Stack>

              <HStack justify="space-between">
                <Checkbox defaultChecked>Remember me</Checkbox>
                <Button variant="text" size="sm">
                  Forgot password?
                </Button>
              </HStack>
              <Stack spacing="6">
                <Button type="submit">Sign in</Button>
                {errorStatus && (
                  <Text fontSize="sm" as={"b"} color="red">
                    There was an error logging you in.
                  </Text>
                )}
                <HStack>
                  <Divider />
                  <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted">
                    or
                  </Text>
                  <Divider />
                </HStack>
                <Text color="fg.muted">
                  Don't have an account? <Link href="#">Sign up</Link>
                </Text>
                {/* <OAuthButtonGroup /> */}
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </form>
    </Container>
  );
}
