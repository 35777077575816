import { Container, Text, Center } from "@chakra-ui/react";
import colors from "../utils/color_codes";

export default function Footer() {
  return (
    <>
      <Container
        maxW="100%"
        h="8vh"
        bg={colors.mainBackgroundColor}
        centerContent
      >
        <Center color={"white"} h="100%">
          <Text size={"xs"}>Copyright © Houston Sikh Youth Camp 2024</Text>
        </Center>
      </Container>
    </>
  );
}
